export enum TermsConfirmType {
  CHECK = 'CHECK',
  IMMEDIATELY_NEW_VERSION = 'IMMEDIATELY_NEW_VERSION',
  IMMEDIATELY_KEEP_VERSION = 'IMMEDIATELY_KEEP_VERSION',
  SCHEDULED_NEW_VERSION = 'SCHEDULED_NEW_VERSION'
}

export enum TermEnforceTimeType {
  IMMEDIATELY = 'IMMEDIATELY',
  SCHEDULED = 'SCHEDULED'
}

export enum TermAgreeType {
  NONE = 'NONE',
  FIRST_MUST = 'FIRST_MUST',
  FIRST_SELECT = 'FIRST_SELECT',
  ALWAYS_MUST = 'ALWAYS_MUST',
  ALWAYS_SELECT = 'ALWAYS_SELECT'
}

export enum TermsTypeId {
  INDIEGAMESTDPRIVACY = 'INDIEGAMESTDPRIVACY',
  INDIEGAMESTUDIOJOIN = 'INDIEGAMESTUDIOJOIN',
  INDIEGAMESTUDIOJOIN_EN = 'INDIEGAMESTUDIOJOINEN'
}
